<template></template>

<script>
export default {
  created() {
    var g = this;
    $.post(api + "/user/login-jwt", {
      jwt: window.location.href.split("/login/")[1],
    }).then(function (d) {
      if (JSON.parse(d).status == 200) {
        alert("حدث خطأ");
        window.location = "/login";
      } else {
        localStorage.setItem("user", JSON.stringify(JSON.parse(d).response));
        window.location = "/";
      }
    });
  },
};
</script>

<style>
</style>